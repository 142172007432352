var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "fb-page",
    [
      _c(
        "fb-header",
        {
          attrs: { slot: "header", title: "诉讼请求", fixed: "" },
          slot: "header",
        },
        [
          _c("fb-button", {
            attrs: {
              slot: "left",
              icon: "back",
              size: "small",
              type: "primary",
            },
            on: {
              click: function ($event) {
                return _vm.$router.back()
              },
            },
            slot: "left",
          }),
        ],
        1
      ),
      _c("div", { staticClass: "content-content" }, [
        _vm.loading
          ? _c(
              "div",
              {
                staticStyle: {
                  display: "flex",
                  "align-items": "center",
                  "justify-content": "center",
                },
              },
              [_c("van-loading")],
              1
            )
          : _vm._e(),
        _c("textarea", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.content,
              expression: "content",
            },
          ],
          staticClass: "content-text",
          domProps: { value: _vm.content },
          on: {
            focus: _vm.DianJi,
            input: function ($event) {
              if ($event.target.composing) return
              _vm.content = $event.target.value
            },
          },
        }),
      ]),
      _c("div", { staticClass: "bottom-btn" }, [
        _c("span", { staticClass: "cancel-btn", on: { click: _vm.back } }, [
          _vm._v("取消"),
        ]),
        _c("span", { staticClass: "save-btn", on: { click: _vm.save } }, [
          _vm._v("保存"),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }